// src/pages/casestudies/index.js

import React from "react"
import data from "../../data/yaml/productstrategy_page.yaml"
import HeadMetadata from "../../components/headMetadata"
import Footer from "../../components/footer"
import ContactSection from "../../components/contact"


export default () => (
  <div>
    <HeadMetadata {...data.metadata} />
    <h1>Case Studies</h1>
    <ContactSection />
    <Footer />
  </div>
)